@charset "UTF-8";
/*
main color: #EA0D8B
*/
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-l {
  float: left;
}

.col-r {
  float: right;
}

div.phpdebugbar {
  text-transform: none !important;
}

body {
  font-family: 'work_sansregular';
  color: #000;
  background: #FFF;
  text-transform: uppercase;
  /*overflow-x: hidden;*/
  -webkit-font-smoothing: antialiased;
}

body.nav-opened .back-button {
  display: none !important;
}

strong {
  font-weight: bold;
}

header.responsive {
  display: none;
}

h1 {
  font-family: 'work_sanslight';
  text-transform: uppercase;
  text-align: center;
  font-size: 25px;
  padding-bottom: 75px;
}

h1 .subtitle {
  text-transform: uppercase;
  display: block;
  font-size: 15px;
}

.preload-data img {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}

#load-screen {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
  overflow: hidden;
  background-color: #FFF;
  background-image: url(../../../public/assets/frontend/grf/loader.gif);
  background-repeat: no-repeat;
  background-size: 64px 64px;
  background-position: center;
  overflow: hidden;
}

::-moz-selection {
  /* Code for Firefox */
  color: #FFF;
  background: #EA0D8B;
}

::selection {
  color: #FFF;
  background: #EA0D8B;
}

.i {
  position: relative;
  width: 80vw;
  margin: auto;
}

.site-frame,
.start.scrollify {
  padding-top: 8vw;
  padding-bottom: 100px;
}

.start.scrollify {
  padding-bottom: 0;
}

/* ratios */
.ratio:before {
  content: "";
  display: block;
  padding-top: 100%;
  /* initial ratio of 1:1*/
}

.ratio2_1:before {
  padding-top: 50%;
}

.ratio1_2:before {
  padding-top: 200%;
}

.ratio4_3:before {
  padding-top: 75%;
}

.ratio16_9:before {
  padding-top: 56.25%;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.site {
  /*min-width: 1200px;*/
}

.start.scrollify {
  /*padding-top: 115px;*/
}

header {
  width: 100%;
  height: 8vw;
  position: fixed;
  top: 0;
  z-index: 999;
  background: #FFF;
  /* jumping fix */
  -webkit-transform: translateZ(0);
}

header .i {
  width: 89%;
  margin: auto;
}

header .i > nav ul {
  padding: 4.5vw 0;
  padding-bottom: 2vw;
}

header .i > nav ul.main {
  float: left;
  font-size: 15px;
}

header .i > nav ul.main:hover li.active a {
  color: #000;
  background: transparent;
}

header .i > nav ul.main li {
  display: block;
  float: left;
  font-size: 1vw;
}

header .i > nav ul.main li:nth-child(2) {
  padding-left: 11.5vw;
}

header .i > nav ul.main li.logo,
header .logo {
  position: absolute;
  top: 15px;
  width: 8.3vw;
  height: 8.3vw;
  max-width: 125px;
  max-height: 125px;
  background: url("../grf/logo.svg") center no-repeat transparent;
  background-size: 100%;
  text-indent: -999px;
}

header .i > nav ul.main li.logo:hover a,
header .logo:hover a {
  background: transparent;
}

header .i > nav ul.main li.logo a,
header .logo a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
}

header .i > nav ul.main li a {
  display: block;
  padding: 0 1.2vw;
  margin-right: 1.5vw;
}

header .i > nav ul.main li.active a,
header .i > nav ul.main li.clicked a,
header .i > nav ul.main li a:hover {
  background: #000;
  color: #FFF;
}

header .i > nav ul.social {
  float: right;
  font-size: 19px;
}

header .i > nav ul.social li {
  display: block;
  float: left;
  margin-left: 15px;
}

.grid.similar {
  padding-top: 35px;
}

main {
  /*margin-top: 160px;*/
}

main .carousel-wrap {
  width: 100%;
  margin: auto;
}

main .carousel div {
  overflow: hidden;
  background: #000;
  background-size: cover;
  height: calc(100vh - 180px);
}

main .carousel img {
  position: absolute;
  max-height: calc(100% - 129px);
}

main .grid > div {
  position: relative;
  width: 25vw;
  height: 14.05vw;
  float: left;
  margin-right: 2.5vw;
  margin-bottom: 2.5vw;
  overflow: hidden;
  background: #000;
  /*max-width: 418px;*/
}

main .grid > div:nth-child(3n) {
  margin-right: 0;
}

main .grid > div img {
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  -moz-transform: scale(1.01) translate3d(0, 0, 0);
  -webkit-transform: scale(1.01) translate3d(0, 0, 0);
  transform: scale(1.01) translate3d(0, 0, 0);
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0.85;
}

main .grid > div:hover img {
  -moz-transform: scale(1.05) translate3d(0, 0, 0);
  -webkit-transform: scale(1.05) translate3d(0, 0, 0);
  transform: scale(1.05) translate3d(0, 0, 0);
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
}

main .grid > div .caption-layer {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
  color: #FFF;
  /*-webkit-backface-visibility: hidden;
			/*backface-visibility: hidden;*/
}

main .grid > div:hover .caption-layer {
  background: rgba(234, 13, 139, 0.8);
  /* safari hack */
  -webkit-box-shadow: 0px 0px 0px 15px rgba(234, 13, 139, 0.8);
  -moz-box-shadow: 0px 0px 0px 15px rgba(234, 13, 139, 0.8);
  box-shadow: 0px 0px 0px 15px rgba(234, 13, 139, 0.8);
}

main .grid > div .caption-layer .wrap {
  display: table-cell;
  vertical-align: middle;
  padding: 25px;
}

main .grid > div .caption-layer span {
  display: block;
}

main .grid > div .caption-layer span.title,
main .grid > div .caption-layer span.subtitle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

main .grid > div .caption-layer span.title {
  font-size: 1.85vw;
  text-transform: none;
  font-family: work_sansmedium;
  line-height: 100%;
}

main .grid > div .caption-layer span.subtitle {
  font-size: 0.85vw;
  text-transform: uppercase;
}

/*

	HOME
	
*/
body.home main .grid {
  padding-top: 50px;
  padding-bottom: 0;
}

body.home .site-frame {
  padding-top: 0;
  padding-bottom: 25px;
}

/*
	DIRECTOR
*/
/*
body.directors main > .i,
body.services main > .i{
	padding-top: 180px;
	padding-bottom: 100px;
}
*/
body.directors main .grid,
body.services main .grid {
  padding-top: 0;
  padding-bottom: 0;
}

body.directors main .grid > div .caption-layer span.title,
body.directors main .grid > div .caption-layer span.subtitle {
  text-transform: uppercase;
}

body.directors.profile main .grid > div .caption-layer span.title,
body.directors.profile main .grid > div .caption-layer span.subtitle {
  text-transform: none;
}

body.directors.profile main h2 {
  text-transform: uppercase;
  text-align: center;
  font-size: 25px;
  padding-bottom: 15px;
  font-weight: normal;
  text-transform: none;
}

body.directors.profile main {
  font-size: 13px;
  line-height: 150%;
}

body.directors.profile section.bio {
  text-transform: none;
  line-height: 200%;
  font-size: 13px;
  margin-top: 30px;
}

body.directors.profile .site-frame {
  padding-top: 10vw;
}

body.directors.profile .site-frame h1 {
  font-family: 'work_sanssemibold';
  padding-bottom: 40px;
}

/*
	PROJECT SHOW
*/
body.project.show header.responsive {
  display: block;
}

body.project.show .back-button {
  position: absolute;
  top: 33px;
  right: 75px;
  display: block;
  width: 28px;
  height: 28px;
}

body.project.show .back-button span {
  top: 13px;
  position: absolute;
  display: block;
  width: 28px;
  height: 2px;
  background-color: #000;
  transform: rotate(45deg);
}

body.project.show .back-button span + span {
  position: absolute;
  transform: rotate(-45deg);
}

body.about .site-frame {
  text-transform: none;
}

body.about .site-frame h1 {
  padding-bottom: 25px;
}

body.about .site-frame p {
  margin-bottom: 25px;
}

header.responsive .logo {
  top: 10px;
  width: 75px;
  height: 75px;
}

header.responsive h1,
header.responsive .heading {
  text-transform: none;
  font-size: 25px;
  padding-bottom: 0;
  height: 90px;
  display: table;
  width: 100%;
  vertical-align: middle;
  text-align: center;
}

header.responsive h1 .wrap,
header.responsive .heading .wrap {
  display: table-cell;
  vertical-align: middle;
}

header.responsive h1 .title,
header.responsive .heading .title {
  font-family: 'work_sanssemibold';
}

header.responsive h1 .subtitle,
header.responsive .heading .subtitle {
  text-transform: uppercase;
  display: block;
  font-size: 14px;
}

header.responsive .menu-icon {
  top: 26px;
}

header.responsive {
  height: 90px;
}

body.project.show .site-frame {
  padding-top: 90px;
}

body.project.show section.credits {
  margin: 45px 0;
  text-align: center;
}

body.project.show section.credits ul {
  text-transform: none;
  display: block;
  max-width: 400px;
  font-size: 12px;
  line-height: 300%;
  margin: auto;
}

body.project.show section.credits ul li {
  border-bottom: 1px solid #000;
}

.project.show main .grid {
  padding-bottom: 0;
}

/*
	CONTACT
*/
body.contact main .i,
body.about main .i {
  width: 89%;
}

body.contact #map-canvas {
  width: 100%;
  height: 400px;
}

body.contact .content {
  margin-bottom: 25px;
  text-transform: none;
}

body.contact .content div {
  float: left;
  font-size: 12px;
  margin-bottom: 20px;
  line-height: 200%;
  padding: 0;
  width: 20%;
}

body.contact .content div:first-child {
  width: 100%;
  float: none;
  margin-bottom: 35px;
}

body.contact .site-frame,
body.about .site-frame {
  width: 76%;
  margin: 0;
  margin-left: 12.7vw;
  padding-top: 165px;
}

main .carousel div .text-layer {
  display: table;
  width: 100%;
  height: 100%;
}

main .carousel div .text-layer .text {
  color: #FFF;
  font-size: 60px;
  width: 100%;
  display: block;
  height: 100%;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  text-transform: none;
  background: transparent;
}

main .carousel div .text-layer .text .title,
main .carousel div .text-layer .text .subtitle {
  display: block;
}

main .carousel div .text-layer .text .title {
  font-size: 5vw;
  font-family: 'work_sansmedium';
}

main .carousel div .text-layer .text .subtitle {
  font-size: 2vw;
}

.video-js {
  padding: 0;
  margin: 0;
  background-color: #000;
}

.vjs-fullscreen {
  background: #000;
}

.vjs-fullscreen #my-video,
.vjs-fullscreen #my-video_html5_api {
  width: 100% !important;
  height: 100% !important;
}

/*
    HAMBURGER MENU
*/
.menu-icon {
  position: absolute;
  right: 0;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  top: 50px;
  z-index: 999;
}

.menu-icon span {
  font-size: 24px;
  position: absolute;
  left: -7.8vw;
  top: 5px;
}

.menu-full {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1;
}

.menu-full nav {
  text-align: center;
  margin-top: 100px;
  font-size: 25px;
  line-height: 190%;
}

.menu-full nav ul.main li a {
  line-height: 24px !important;
  height: 22px;
  display: inline-block;
  padding: 0 12px;
}

.menu-full nav ul.main li a:hover {
  background: #000;
  color: #FFF;
}

/*
    ABOUT
*/
body.about .site-frame {
  padding-top: 160px;
  font-size: 12px;
}

body.about .site-frame h1 {
  font-family: 'work_sanssemibold';
  text-transform: none;
  text-align: left;
}

body.about .site-frame article {
  margin-bottom: 65px;
}

body.about .site-frame article:last-child {
  margin-bottom: 0;
}

body.about .site-frame ul {
  /*
	max-width: 500px;
	margin: auto;
	margin-bottom: 45px;
	*/
  margin-bottom: 55px;
}

body.about .site-frame ul li::before {
  content: "• ";
  color: #EA0D8B;
  font-size: 22px;
  position: relative;
  top: 3px;
}

body.about .site-frame .highlight {
  /*color: #EA0D8B;*/
  font-weight: bold;
  text-decoration: underline;
}

body.about .site-frame .highlight.primary {
  /*color: #EA0D8B;*/
}

footer {
  font-size: 12px;
}

footer .i {
  padding: 35px 0;
  padding-top: 0;
  border-top: 1px solid #000;
}

footer .wrap {
  width: 100%;
  position: absolute;
  bottom: 0;
}

footer .col-l,
footer .col-r {
  width: 50%;
  height: 42px;
  position: relative;
}

footer .col-r {
  text-align: right;
}

footer .col-r ul.social li {
  display: block;
  float: right;
  margin-left: 12px;
  font-size: 20px;
}

/* Icon 1 */
#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  /*width: 60px;*/
  width: 48px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 18px;
}

#nav-icon1 span:nth-child(3) {
  top: 36px;
}

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* Icon 2 */
#nav-icon2 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: #000;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 18px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 36px;
}

#nav-icon2.open span:nth-child(1), #nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2), #nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}

/* Icon 3 */
#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
  top: 18px;
}

#nav-icon3 span:nth-child(4) {
  top: 36px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/* Icon 4 */
#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}

@media screen and (max-width: 1200px) {
  body.contact .content div {
    width: 25%;
  }
}

@media screen and (max-width: 1000px) {
  body.contact .content div {
    width: 33.3%;
  }
}

@media screen and (max-width: 800px) {
  body.contact .content div {
    width: 50%;
  }
}

@media screen and (max-width: 550px) {
  body.contact .content div {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  header.main {
    display: none;
  }
  header.responsive {
    display: block;
  }
  main .grid > div {
    width: 38.75vw;
    height: 21.7vw;
    margin-right: 0;
  }
  main .grid > div:nth-child(2n) {
    margin-left: 2.5vw;
  }
  main .grid > div .caption-layer span.title {
    font-size: 2.85vw;
  }
  main .grid > div .caption-layer span.subtitle {
    font-size: 1.35vw;
  }
  .site-frame, .start.scrollify {
    padding-top: 90px;
  }
}

@media screen and (max-width: 600px) {
  .i {
    width: 85vw;
  }
  main .grid {
    padding-top: 20px;
  }
  main .grid > div {
    width: 100%;
    height: 47.65vw;
    margin-right: 0;
  }
  main .grid > div:nth-child(2n) {
    margin-left: 0;
  }
  main .grid > div .caption-layer span.title {
    font-size: 6.85vw;
  }
  main .grid > div .caption-layer span.subtitle {
    font-size: 3.35vw;
  }
  header.responsive h1 .title,
  header.responsive .heading .title {
    font-size: 15px;
  }
  header.responsive h1 .subtitle,
  header.responsive .heading .subtitle {
    font-size: 10px;
  }
}

@media screen and (min-width: 1465px) {
  header {
    height: 117px;
  }
  header .i > nav ul.main li:nth-child(2) {
    padding-left: 175px;
  }
  header .i > nav ul.main li {
    font-size: 15px;
  }
  header .i > nav ul.main {
    padding-top: 65px;
  }
  body.contact .site-frame,
  body.about .site-frame {
    margin-left: 198px;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.about p {
  line-height: 180%;
}

body.about .about-gallery .item {
  width: 23%;
  float: left;
  margin-right: 0.25%;
}

body.about .about-gallery .item.img001 {
  width: 51.6%;
}

@media screen and (max-width: 800px) {
  body.about .about-gallery .item.img001 {
    width: 100%;
    margin: 0;
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 800px) {
  body.about .about-gallery .item.img002, body.about .about-gallery .item.img003 {
    width: calc(50% - 1px);
    margin-right: 1px;
  }
}

@media screen and (max-width: 800px) {
  body.about .about-gallery .item.img003 {
    margin-left: 1px;
    margin-right: 0;
  }
}

body.intro {
  font-size: 13px;
}

body.intro footer {
  display: none;
}

body.intro article {
  margin-top: 90px;
}

body.intro h1 {
  text-transform: none;
  padding-bottom: 20px;
  font-size: 15px;
  font-weight: bold;
  font-family: 'work_sanssemibold';
}

body.intro p {
  line-height: 150%;
  text-transform: none;
  text-align: center;
  font-size: 15px;
  margin-bottom: 35px;
  font-family: 'work_sanslight';
}

body.intro .intro-gallery .item {
  display: block;
  width: 49.5%;
  float: left;
  margin-right: 1%;
}

body.intro .intro-gallery .item img {
  width: 100%;
}

@media screen and (max-width: 600px) {
  body.intro .intro-gallery .item {
    width: 100%;
  }
}

body.intro .intro-gallery .item + .item {
  margin-right: 0;
}
