@font-face {
    font-family: 'work_sansextralight';
    src: url('../fonts/worksans/worksans-extralight-webfont.eot');
    src: url('../fonts/worksans/worksans-extralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans/worksans-extralight-webfont.woff2') format('woff2'),
         url('../fonts/worksans/worksans-extralight-webfont.woff') format('woff'),
         url('../fonts/worksans/worksans-extralight-webfont.ttf') format('truetype'),
         url('../fonts/worksans/worksans-extralight-webfont.svg#work_sansextralight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'work_sansregular';
    src: url('../fonts/worksans/worksans-regular-webfont.eot');
    src: url('../fonts/worksans/worksans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans/worksans-regular-webfont.woff2') format('woff2'),
         url('../fonts/worksans/worksans-regular-webfont.woff') format('woff'),
         url('../fonts/worksans/worksans-regular-webfont.ttf') format('truetype'),
         url('..//fonts/worksans/worksans-regular-webfont.svg#work_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'work_sanslight';
    src: url('../fonts/worksans/worksans-light-webfont.eot');
    src: url('../fonts/worksans/worksans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans/worksans-light-webfont.woff2') format('woff2'),
         url('../fonts/worksans/worksans-light-webfont.woff') format('woff'),
         url('../fonts/worksans/worksans-light-webfont.ttf') format('truetype'),
         url('..//fonts/worksans/worksans-light-webfont.svg#work_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'work_sansmedium';
    src: url('../fonts/worksans/worksans-medium-webfont.eot');
    src: url('../fonts/worksans/worksans-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans/worksans-medium-webfont.woff2') format('woff2'),
         url('../fonts/worksans/worksans-medium-webfont.woff') format('woff'),
         url('../fonts/worksans/worksans-medium-webfont.ttf') format('truetype'),
         url('../fonts/worksans/worksans-medium-webfont.svg#work_sansmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'work_sansbold';
    src: url('../fonts/worksans/worksans-bold-webfont.eot');
    src: url('../fonts/worksans/worksans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans/worksans-bold-webfont.woff2') format('woff2'),
         url('../fonts/worksans/worksans-bold-webfont.woff') format('woff'),
         url('../fonts/worksans/worksans-bold-webfont.ttf') format('truetype'),
         url('../fonts/worksans/worksans-bold-webfont.svg#work_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'work_sanssemibold';
    src: url('../fonts/worksans/worksans-semibold-webfont.eot');
    src: url('../fonts/worksans/worksans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans/worksans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/worksans/worksans-semibold-webfont.woff') format('woff'),
         url('../fonts/worksans/worksans-semibold-webfont.ttf') format('truetype'),
         url('../fonts/worksans/worksans-semibold-webfont.svg#work_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}